import Text from "@mkt/ui/components/Azulis/Text"
import { CheckBlue } from "@mkt/ui/icons/Azulis"
import SbEditable from "@mkt/ui/components/common/SbEditable"
import { render } from "storyblok-rich-text-react-renderer"

import * as S from "./styles"

const Details = ({ blok }) => {
  if (!blok) return ""
  return (
    <ul css={S.Details}>
      <SbEditable content={blok}>
        {blok?.map((childBlok) => {
          const { _uid: id, title, desc } = childBlok
          return (
            <SbEditable content={childBlok} key={`details-azulis-${id}`}>
              <li>
                <div css={S.DetailsInfoContent}>
                  <div css={S.DetailsImage}>
                    <CheckBlue />
                  </div>
                  <Text as="p" variant={["Line30", "Text14"]}>
                    {title}:
                  </Text>{" "}
                  <Text as="strong" variant={["Bold", "Line30", "Text14"]}>
                    {render(desc)}
                  </Text>
                </div>
              </li>
            </SbEditable>
          )
        })}
      </SbEditable>
    </ul>
  )
}

export default Details
