import { css } from "@emotion/react"
import { above } from "@mkt/ui/styles/Breakpoints"

export const Details = css`
  ${above["l"]} {
    margin-right: 32px;
  }
`

export const DetailsInfoContent = css`
  display: flex;
  align-content: center;
  align-items: center;
  gap: 6px;
  padding-bottom: 16px;

  .icon + p {
    margin-left: 16px;
    display: flex;
    align-content: center;
  }
`

export const DetailsImage = css`
  & > div {
    width: 12px;
    height: 10px;
    margin-right: 6px;
  }
`
