import { css } from "@emotion/react"
import { above } from "@mkt/ui/styles/Breakpoints"
import { container } from "@mkt/ui/styles/Functions"

export const LoanContentContainer = css`
  ${container};
`

export const LoanContentWrap = css`
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  max-width: 440px;
  background-color: #fff;
  padding: 12px 21px;
  margin: 0 auto;

  ${above["l"]} {
    max-width: 1280px;
    flex-direction: row;
    box-shadow: 0 4px 12px 2px rgb(198 195 195 / 35%);
    margin-bottom: 28px;
    padding: 36px 56px;
  }
`

export const LoanContentCard = css`
  display: flex;
  width: 100%;
  align-content: flex-start;
  padding: 8px;
  border-right: none;
  flex-direction: column;

  ${above["l"]} {
    width: 66%;
    border-right: 1px solid #e1e1e1;
  }

  & > h3 {
    padding-top: 24px;
    margin-bottom: 24px;
    ${above["l"]} {
      padding-top: 12px;
    }
  }
`

export const LoanFormContainer = css`
  width: 100%;

  ${above["l"]} {
    width: 33%;
    margin-left: 35px;
  }
`
