import Text from "@mkt/ui/components/Azulis/Text"
import Condition from "@mkt/ui/components/common/Condition"

import Details from "../Details"
import * as S from "./styles"

const Content = ({ content, children }) => {
  return (
    <div className="loans-content">
      <div css={S.LoanContentContainer}>
        <div css={S.LoanContentWrap}>
          <div css={S.LoanContentCard}>
            <Text as="h3" variant="HeadingFour">
              Mais detalhes:
            </Text>

            <Condition
              condition={
                !!content?.loans_info && content?.loans_info.length > 0
              }
            >
              <Details blok={content.loans_info} />
            </Condition>
          </div>

          <div css={S.LoanFormContainer} data-id-ui="form-flow">
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Content
