import Condition from "@mkt/ui/components/common/Condition"
import Img from "@mkt/ui/components/common/Img"
import Text from "@mkt/ui/components/Azulis/Text"

import * as S from "./styles"

const Hero = ({ content }) => {
  return (
    <Condition condition={!!content}>
      <div className="article-hero">
        <div css={S.heroContainer}>
          <figure css={S.heroImageContainer}>
            <Img
              src={content.image.filename}
              alt={content.title}
              css={S.heroImage}
            />
          </figure>
          <div css={S.heroTitle}>
            <Text as="h2" variant={["HeadingFour", S.TitleHero]}>
              Empréstimo {content?.title}
            </Text>
          </div>
          <ul css={S.heroDetailList}>
            <li css={S.heroDetailListItem}>
              <Text as="h3" variant={["HeadingFour", S.LoanPercentage]}>
                {content?.minimum_interest}
              </Text>
              <Text as="p" variant={["Text16", "SemiBold"]}>
                juros mínimo{" "}
                <Text as="strong" variant={["Text16", "Bold"]}>
                  ao mês
                </Text>
              </Text>
            </li>
          </ul>
        </div>
      </div>
    </Condition>
  )
}

export default Hero
