import DefaultLayout from "../../layout/DefaultLayout"
import { withFormMarketplace } from "@mkt/firebolt/config"
import ProductPageTemplate from "@mkt/ui/templates/ProductPage"

import Content from "./components/Content"
import Hero from "./components/Hero"

const Loan = ({ pageContext, location }) => {
  const AzulisLogo =
    "https://assets.iq.com.br/f/114280/x/c7943e9d94/logo-azulis-redirect.svg"
  return (
    <DefaultLayout pageContext={pageContext} location={location} >
      <ProductPageTemplate
        pageContext={pageContext}
        LogoRedirect={AzulisLogo}
        ComponentHero={Hero}
        ComponentContent={Content}
      />
    </DefaultLayout>
  )
}

export default withFormMarketplace(Loan)
